import Vue from "vue";
import Vuex from "vuex";
import { Flour, Starter, Bread, Recipe } from "../types";
import compute from "../compute";
import clone from "just-clone";

Vue.use(Vuex);

const storageKey = "breads_v2";

function convertFromLegacyStorage() {
  const v1Breads = convertFromV1();
  if (v1Breads.length > 0) {
    saveToStorage(v1Breads);
  }
}

function convertFromV1(): Bread[] {
  interface BreadV1 {
    name: string;
    totalWeight: number;
    hydration: number;
    starterPerc: number;
    saltPerc: number;
    targetProtein: number;
    starter: Starter;
    flours: FlourV1[];
  }
  interface FlourV1 {
    name: string;
    protein: number;
    amount: number;
  }

  function discardBread(b: BreadV1) {
    console.log(
      "Discarding bread " +
        b.name +
        " from saved breads. Conversion not possible."
    );
    return null;
  }

  const v1Key = "breads_v1";
  const loaded = localStorage.getItem(v1Key);
  if (loaded != null) {
    console.log("Converting breads from v1...");
    try {
      const breads: BreadV1[] = JSON.parse(loaded);
      const newBreads = breads
        .map((b) => {
          const flours: FlourV1[] = b.flours;

          // Filter out the gluten from the flours
          const glutenIndex = flours.findIndex((f) => f.name == "gluten");
          if (glutenIndex == -1 || flours[glutenIndex].amount) {
            return discardBread(b);
          }
          const glutenProtein = flours[glutenIndex].protein;
          flours.splice(glutenIndex, 1);

          // Filter out the main flour
          const mainFlourIndex = flours.findIndex((f) => f.amount == null);
          if (mainFlourIndex == -1) {
            return discardBread(b);
          }
          const mainFlourName = flours[mainFlourIndex].name;
          const mainFlourProtein = flours[mainFlourIndex].protein;
          flours.splice(mainFlourIndex, 1);

          return {
            name: b.name,
            totalWeight: b.totalWeight,
            hydration: b.hydration,
            starterPerc: b.starterPerc,
            saltPerc: b.saltPerc,
            useProteinTarget: true,
            proteinTarget: {
              target: b.targetProtein,
              gluten: glutenProtein,
            },
            starter: b.starter,
            mainFlour: { name: mainFlourName, protein: mainFlourProtein },
            flours: flours.map((f) => ({
              flour: { name: f.name, protein: f.protein },
              amount: f.amount,
            })),
          } as Bread;
        })
        .filter((b): b is Bread => b !== null);
      localStorage.removeItem(v1Key);
      return newBreads;
    } catch (e) {
      console.log("Converting breads from v1 failed: " + e);
      localStorage.removeItem(v1Key);
    }
  }
  return [];
}

function loadFromStorage(): Bread[] {
  convertFromLegacyStorage();
  try {
    const loaded = localStorage.getItem(storageKey);
    return JSON.parse(loaded || "[]");
  } catch (e) {
    localStorage.removeItem(storageKey);
    return [];
  }
}

function saveToStorage(breads: Bread[]): void {
  const v = JSON.stringify(breads);
  localStorage.setItem(storageKey, v);
}

const store = new Vuex.Store({
  state: {
    bread: {
      name: "My awesome bread",
      totalWeight: 700,
      hydration: 65,
      starterPerc: 20,
      saltPerc: 2,
      useProteinTarget: true,
      proteinTarget: {
        target: 15,
        gluten: 80,
      },
      starter: {
        name: "yeasti",
        hydration: 100,
        protein: 11,
      } as Starter,
      mainFlour: {
        name: "550",
        protein: 10.6,
      } as Flour,
      flours: [{ flour: { name: "whole wheat", protein: 11.6 }, amount: 20 }],
    } as Bread,
    savedBreads: loadFromStorage(),
  },
  mutations: {
    // General
    setName(state, name) {
      state.bread.name = name;
    },
    setTotalWeight(state, totalWeight) {
      state.bread.totalWeight = totalWeight;
    },
    setHydration(state, hydration) {
      state.bread.hydration = hydration;
    },
    setStarterPerc(state, starterPerc) {
      state.bread.starterPerc = starterPerc;
    },
    setSaltPerc(state, saltPerc) {
      state.bread.saltPerc = saltPerc;
    },
    setTargetProtein(state, targetProtein) {
      state.bread.proteinTarget.target = targetProtein;
    },
    setGlutenProtein(state, glutenProtein) {
      state.bread.proteinTarget.gluten = glutenProtein;
    },
    setUseProteinTarget(state, useProteinTarget) {
      state.bread.useProteinTarget = useProteinTarget;
    },
    // Starter
    setStarterName(state, name) {
      state.bread.starter.name = name;
    },
    setStarterHydration(state, hydration) {
      state.bread.starter.hydration = hydration;
    },
    setStarterProtein(state, protein) {
      state.bread.starter.protein = protein;
    },
    // Main flour
    setMainFlourName(state, name) {
      state.bread.mainFlour.name = name;
    },
    setMainFlourProtein(state, protein) {
      state.bread.mainFlour.protein = protein;
    },
    // Flour
    addFlour(state) {
      state.bread.flours.push({
        flour: {
          name: "flour-" + state.bread.flours.length,
          protein: 10,
        },
        amount: 20,
      });
    },
    removeFlour(state, index) {
      state.bread.flours.splice(index, 1);
    },
    setFlourName(state, payload) {
      state.bread.flours[payload.index].flour.name = payload.name;
    },
    setFlourProtein(state, payload) {
      state.bread.flours[payload.index].flour.protein = payload.protein;
    },
    setFlourAmount(state, payload) {
      state.bread.flours[payload.index].amount = payload.amount;
    },

    // Loading + Saving of breads
    loadBread(state, name) {
      const v = state.savedBreads.find((b) => b.name == name);
      if (v) {
        state.bread = v;
      }
      // TODO handle the else case??
    },
    deleteBread(state, name) {
      const index = state.savedBreads.findIndex((b) => b.name == name);
      state.savedBreads.splice(index, 1);
      saveToStorage(state.savedBreads);
    },
    saveBread(state) {
      const index = state.savedBreads.findIndex(
        (b) => b.name == state.bread.name
      );
      if (index == -1) {
        state.savedBreads.push(clone(state.bread));
      } else {
        state.savedBreads.splice(index, 1, clone(state.bread));
      }
      saveToStorage(state.savedBreads);
    },
  },
  getters: {
    recipe(state): Recipe {
      return compute(state.bread);
    },
    numFlours(state) {
      return state.bread.flours.length;
    },
    breadNames(state): string[] {
      return state.savedBreads.map((b) => b.name) as string[];
    },
  },
});
export default store;
