import { render, staticRenderFns } from "./GlutenInput.vue?vue&type=template&id=0f37d8b1&scoped=true&"
import script from "./GlutenInput.vue?vue&type=script&lang=ts&"
export * from "./GlutenInput.vue?vue&type=script&lang=ts&"
import style0 from "./GlutenInput.vue?vue&type=style&index=0&id=0f37d8b1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f37d8b1",
  null
  
)

export default component.exports