import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

Vue.config.productionTip = false;

// Bootstrap-Vue components
import {
  LayoutPlugin,
  ModalPlugin,
  ButtonPlugin,
  FormInputPlugin,
  ListGroupPlugin,
} from "bootstrap-vue";

Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormInputPlugin);
Vue.use(ListGroupPlugin);

// Root SCSS
import "./style.scss";

// Create Vue instance
import store from "./store";
new Vue({
  render: (h) => h(App),
  store,
}).$mount("#app");
