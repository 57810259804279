




























import { Vue } from "vue-property-decorator";
import NumberInput from "./NumberInput.vue";
import StringInput from "./StringInput.vue";

export default Vue.component("MainFlourInput", {
  computed: {
    showProtein() {
      return this.$store.state.bread.useProteinTarget;
    },
    flour() {
      return this.$store.state.bread.mainFlour;
    },
  },
  methods: {
    updateName(name: string) {
      this.$store.commit("setMainFlourName", name);
    },
    updateProtein(protein: number) {
      this.$store.commit("setMainFlourProtein", protein);
    },
  },
  components: {
    NumberInput,
    StringInput,
  },
});
