















































import { Vue } from "vue-property-decorator";

export default Vue.component("SavingDialog", {
  computed: {
    name() {
      return this.$store.state.bread.name;
    },
  },
  methods: {
    updateName(name: string) {
      this.$store.commit("setName", name);
    },
    saveCheckOverwrite() {
      // TODO do not close save modal if the overwrite warning comes
      const savedNames = this.$store.getters.breadNames;
      if (savedNames.includes(this.name)) {
        this.$bvModal.show("overwrite");
      } else {
        this.save();
      }
    },
    save() {
      // TODO disallow to use the same name twice
      this.$store.commit("saveBread");
    },
  },
});
