



































































import { Vue } from "vue-property-decorator";
import NumberInput from "./components/NumberInput.vue";
import GlutenInput from "./components/GlutenInput.vue";
import StarterInput from "./components/StarterInput.vue";
import MainFlourInput from "./components/MainFlourInput.vue";
import FlourInput from "./components/FlourInput.vue";
import RecipeTable from "./components/RecipeTable.vue";
import SavingDialog from "./components/SavingDialog.vue";
import LoadingDialog from "./components/LoadingDialog.vue";
import { Bread } from "./types";

export default Vue.component("App", {
  computed: {
    bread(): Bread {
      return this.$store.state.bread;
    },
    numFlours(): number {
      return this.$store.getters.numFlours;
    },
  },
  methods: {
    updateTotalWeight(totalWeight: number) {
      this.$store.commit("setTotalWeight", totalWeight);
    },
    updateHydration(hydration: number) {
      this.$store.commit("setHydration", hydration);
    },
    updateStarterPerc(starterPerc: number) {
      this.$store.commit("setStarterPerc", starterPerc);
    },
    updateSaltPerc(saltPerc: number) {
      this.$store.commit("setSaltPerc", saltPerc);
    },
    addFlour() {
      this.$store.commit("addFlour");
    },
  },
  components: {
    NumberInput,
    GlutenInput,
    StarterInput,
    MainFlourInput,
    FlourInput,
    RecipeTable,
    SavingDialog,
    LoadingDialog,
  },
});
