





































































import { Vue } from "vue-property-decorator";

export default Vue.component("FlourInput", {
  props: ["index"],
  computed: {
    showProtein() {
      return this.$store.state.bread.useProteinTarget;
    },
    flour() {
      return this.$store.state.bread.flours[this.index].flour;
    },
    amount() {
      return this.$store.state.bread.flours[this.index].amount;
    },
  },
  methods: {
    remove() {
      this.$store.commit("removeFlour", this.index);
    },
    updateName(name: string) {
      this.$store.commit("setFlourName", {
        index: this.index,
        name: name,
      });
    },
    updateProtein(protein: number) {
      this.$store.commit("setFlourProtein", {
        index: this.index,
        protein: protein,
      });
    },
    updateAmount(amount: number) {
      this.$store.commit("setFlourAmount", {
        index: this.index,
        amount: amount,
      });
    },
  },
});
