


























// TODO handle with of label differently?
import { Vue } from "vue-property-decorator";

export default Vue.component("NumberInput", {
  props: {
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    labelWidth: {
      type: String,
      default: "11em",
    },
  },
  methods: {
    update(e: Event) {
      const target = e.target as HTMLInputElement;
      this.$emit("input", parseFloat(target.value));
    },
  },
});
