








































import { Recipe } from "@/types";
import { Vue } from "vue-property-decorator";

export default Vue.component("RecipeTable", {
  computed: {
    recipe(): Recipe {
      return this.$store.getters.recipe;
    },
  },
});
