

























































import { Vue } from "vue-property-decorator";
import { BIconTrash } from "bootstrap-vue";

export default Vue.component("LoadingDialog", {
  data() {
    return {
      selected: "",
    };
  },
  computed: {
    names(): string[] {
      return this.$store.getters.breadNames;
    },
  },
  methods: {
    loadSelected() {
      this.$store.commit("loadBread", this.selected);
    },
    deleteSelected() {
      this.$store.commit("deleteBread", this.selected);
    },
  },
  components: {
    BIconTrash,
  },
});
