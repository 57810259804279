








































import { Vue } from "vue-property-decorator";

export default Vue.component("GlutenInput", {
  computed: {
    useTarget() {
      return this.$store.state.bread.useProteinTarget;
    },
    target() {
      return this.$store.state.bread.proteinTarget.target;
    },
    gluten() {
      return this.$store.state.bread.proteinTarget.gluten;
    },
  },
  methods: {
    updateUseTarget(e: Event) {
      const etarget = e.target as HTMLInputElement;
      this.$store.commit("setUseProteinTarget", etarget.checked);
    },
    updateTarget(target: number) {
      this.$store.commit("setTargetProtein", target);
    },
    updateGluten(gluten: number) {
      this.$store.commit("setGlutenProtein", gluten);
    },
  },
});
